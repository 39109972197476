import React from "react";


export default function About() {
	return (
		<div className="content__about">
			<h2 className="content__heading">About</h2>
			<p>I am a junior full stack developer with a passion for making fantastic designs and amazing functioning code. I take a particular interest coding with JavaScript and PHP for automated and dynamic content.</p>
			<p>I started teaching myself skills in web development in 2013 after I finished college in 2012 where I learnt the basics. After 7 years of teaching myself, I started studying Web Programming BSc{"(Hons)"} at degree level in 2020.</p>
		</div>
	)
}