import React from "react";
import ReactDOM from "react-dom";

import "reset-css";
import "./styles.css";

import App from "./App";


const root = document.getElementById("root");

ReactDOM.render(<App />, root);