import React from "react";
import { PublicURL } from "../functions.js"

export default function Header() {
	return (
		<header className="header">
			<img className="header__image" src={PublicURL + "./images/Photo of me.jpg"} alt="Stuart Norman" />
			<div className="header__info --bg">
				<div className="header-container">
					<h1 className="header__info__name --txtAlign-center">Stuart Norman</h1>
					<h2 className="header__info__profession --txtAlign-center">Full Stack Developer</h2>
					<h3 className="header__info__website --txtAlign-center">
						<a href='https://portfolio.sjndesigns.co.uk' target="_blank" rel="noreferrer">portfolio.sjndesigns.co.uk</a>
					</h3>
				</div>
				<div className="header__info__btns">
					<Btn type="email" url="mailto:stuart-norman@hotmail.com" />
					<Btn type="linkedin" url="https://www.linkedin.com/in/sj-norman/" />
				</div>
			</div>
		</header>
	)
}

function Btn(props) {
	let icon;
	
	if (props.type === "email") {
		icon = "fa-solid fa-envelope";
	}
	else if (props.type === "linkedin") {
		icon = "fa-brands fa-linkedin";
	}

	const text = props.type.charAt(0).toUpperCase() + props.type.slice(1);

	return (
		<a href={props.url} className={"btn btn-" + props.type} target="_blank" rel="noreferrer"><i className={icon}></i><span>{text}</span></a>
	)
}