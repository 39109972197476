import React from "react";
import Header from "./Components/Header";
import Content from "./Components/Content";
import Footer from "./Components/Footer";

export default function App() {
	return (
		<div className="container">
			<Header />
			<Content />
			<Footer />
		</div>
	)
}