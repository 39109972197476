import { PublicURL } from "../functions.js"

export default function Footer() {
	const yearNow = new Date().getFullYear();

	return (
		<footer className="footer --bg --txtAlign-center">
			<div className="footer__icons">
				<Icon type="twitter" url="https://twitter.com/yCodeTech" />
				<Icon type="fb" url="https://www.facebook.com/sjnormandesigns" />
				<Icon type="insta" url="https://www.instagram.com/studocwho" />
				<Icon type="github" url="https://github.com/yCodeTech" />
			</div>
			<div className="footer__copyright">Copyright © {yearNow} Stuart Norman. All rights reserved.</div>
			<img className="footer__react-logo" src={PublicURL + "./images/logo512.png"} alt="React Logo" />
		</footer>
	)
}

function Icon(props) {
	var icon;
	
	if (props.type === "twitter") {
		icon = "fa-brands fa-twitter"
	}
	else if (props.type === "fb") {
		icon = "fa-brands fa-facebook"
	}
	else if (props.type === "insta") {
		icon = "fa-brands fa-instagram"
	}
	else if (props.type === "github") {
		icon = "fa-brands fa-github"
	}

	return (
		<a className={"icon icon-"+ props.type} href={props.url} target="_blank" rel="noreferrer">
			<i className={icon}></i>
		</a>
	)

}
