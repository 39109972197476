import React from "react";
import About from "./About";
import Interests from "./Interests";

export default function Content() {
	const myInterests = [
		"Web development",
		"Music",
		"Whovian",
		"Burgers and pizzas",
		"Chocolate",
		"Ancient Egyptian history",
		"Video games"
	]

	return (
		<section className="content --bg">
			<About />
			<Interests interests={myInterests}/>
		</section>
	)
}