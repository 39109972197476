export default function Interests(props) {

	return (
		<div className="content__interests">
			<h2 className="content__heading">Interests</h2>
			<ul className="interest-list">
				{props.interests.map((interest, i) => (
					<li key={i} className="list-item --txtAlign-center">{interest}</li>
				))}
			</ul>
		</div>
	)
}